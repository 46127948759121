import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../style/signup-modal.css';

function MailModal(props) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => {
      props.setShowModal(false);
      setShow(false);
    };
    const handleShow = () => setShow(true);

    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const [shown, setShown] = useState(false);

    const shownRef = useRef(shown);
    shownRef.current = shown;

    useEffect(() => {
      const timer = setTimeout(() => {
        if(shownRef.current == false){
          setShow(true);
          setShown(true);
        }
      }, 20_000);
      return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
      function onScroll() {
        let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
        if (currentPosition > scrollTop) {
          // downscroll code
          setScrolling(false);
        } else {
          // upscroll code
          setScrolling(true);
        }
        setScrollTop(currentPosition <= 0 ? 0 : currentPosition);

        if(currentPosition >= 1600 && shown == false){
          setShow(true);
          setShown(true);
        }
      }

      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop, shown]);
  
    return (
      <>
        <Modal show={show || props.showModal} onHide={handleClose} id="signup-modal">
          <Modal.Header closeButton>
            <Modal.Title className="w-100">Get Updates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/*<div style={{"paddingBottom":"15px"}}>I'm just a guy building the game I've always dreamed of.</div>
            <div>This game doesn't exist yet.  But it will some time soon, so it would mean a lot to me if you signed up for updates.</div>*/}
            Subscribe to our mailing list! <br /> Get exclusive updates and playtest invites!
          </Modal.Body>
          <Modal.Body>
            <form action="https://gmail.us7.list-manage.com/subscribe/post?u=0ed23946107d201041b5060c7&amp;id=01ffd01447" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">
                  <div className="mc-field-group">
                      <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Email address" />
                  </div>
                  <div id="mce-responses" className="clear">
                      <div className="response" id="mce-error-response" style={{"display": "none"}}></div>
                      <div className="response" id="mce-success-response" style={{"display": "none"}}></div>
                  </div>
                  <div style={{"position": "absolute", "left": "-5000px"}} aria-hidden="true">
                      <input type="text" name="b_0ed23946107d201041b5060c7_01ffd01447" tabindex="-1" value="" />
                  </div>
                  <div className="clear">
                      <div id="mc-embedded-subscribe-outer">
                      <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                      </div>
                  </div>
              </div>
          </form>            
          </Modal.Body>
        </Modal>
      </>
    );
  }
  
  export default MailModal;