import React from 'react';
import '../style/header.css';
import {
    Link
  } from "react-router-dom";

function Header(props) {
    return <div id="header">
        <div className="item"><a href="/">Ser.S</a></div>
        <div className="item float-right"><Link to="/web-privacy-policy">Privacy</Link></div>
    </div>;
}

export default Header;