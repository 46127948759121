import React from 'react';
import Header from './header';

function MusicLandingPage(props){
    return(
        <React.Fragment>
        <Header></Header>
        <iframe 
            src={props.url}
            style={
                {
                    "width": "100%", 
                    "height": "100vh",
                    "border": "0px", 
                    "padding": "0px", 
                    "margin": "0px", 
                    "display": "block", 
                    "backgroundColor": "black", 
                    "position": "absolute", 
                    "top": "0px",
                    "z-index": "-1"
                }
            }
        >
        </iframe>
        </React.Fragment>
    )
}

export default MusicLandingPage;