import React from 'react';
import './style/App.css';
import './style/title-box.css';
import Header from './components/header';
import './style/role-cards.css'; 
import './style/footer.css'; 
import Footer from './components/footer';
import Home from './components/home';
import PrivacyPolicy from './components/privacy-policy';
import WebPrivacy from './components/web-privacy';
import LandingPage from './components/landing-page';
import PylotDL from './components/pylot-dl';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/tasteless">
          <LandingPage url={"https://mailchi.mp/c6a41617adef/6okh9apbvh"}></LandingPage>
        </Route>
        <Route path="/pylot">
          <LandingPage url={"https://mailchi.mp/6399d03ab1ed/pylot-song-giveaway"}></LandingPage>
        </Route>
        <Route>
          <div className="App">
            <Header />
            <Switch>
              <Route path="/web-privacy-policy">
                <WebPrivacy />
              </Route>
              <Route path="/pylot-dl">
                <PylotDL />
              </Route>
              <Route path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route path="/datarequest">
                <div style={{"width":"500px", "height":"300px", "backgroundColor": "white", "padding": "125px 50px", "margin": "130px auto", "borderRadius": "5px"}}>
                  If you wish to remove personal data from our systems, please email <a href="mailto:farplanetgames@gmail.com">farplanetgames@gmail.com</a> for assistance.
                  </div>
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
            <Footer />
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;