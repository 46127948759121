import React from 'react';
import '../style/mailchimp.css';

function MailchimpForm(props) {
    return (
    <div id="mc_embed_signup">
        <form action="https://gmail.us7.list-manage.com/subscribe/post?u=0ed23946107d201041b5060c7&amp;id=01ffd01447" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
                <h2 style={{"color": "orange", "paddingBottom": "0px"}}>SUBSCRIBE TO GET PLAYTEST INVITES</h2>
                <h3>Subscribe to our development newsletter, and get exclusive invites to our free playtests!️</h3>
                <div className="mc-field-group">
                    {/*<label for="mce-EMAIL">Email Address  <span className="asterisk">*</span></label>*/}
                    <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Email address" />
                </div>
                <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{"display": "none"}}></div>
                    <div className="response" id="mce-success-response" style={{"display": "none"}}></div>
                </div>
                <div style={{"position": "absolute", "left": "-5000px"}} aria-hidden="true">
                    <input type="text" name="b_0ed23946107d201041b5060c7_01ffd01447" tabindex="-1" value="" />
                </div>
                <div className="clear">
                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                </div>
            </div>
        </form>
    </div>);
}

export default MailchimpForm;