import React from 'react';
import '../style/body-section.css';

function BodySection(props){
    return (
        <div className="body-section">
            {props.children}
        </div>
    )
}

export default BodySection;