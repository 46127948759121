import React from 'react';
import '../style/privacy-policy.css';

function PrivacyPolicy(props){
    return <div id="privacy-policy">
        <h1 class="center">FAR PLANET GAMES</h1>
        <h2 class="center">Privacy Policy</h2>
        <p class="center">Last modified on 11/28/2020</p>

        <h2>1. What’s in this Privacy Policy?</h2>
        <p>In this Privacy Policy, you’ll find:
            <ul>
                <li>What information we collect about you</li>
                <li>How we might use that information</li>
                <li>What information we might share with others</li>
                <li>Your rights and choices about that information</li>
            </ul>
        </p>
        <h2>2. What does this Privacy Policy cover?</h2>
        <p>We are Far Planet Games LLC.</p>
        <p>In this Privacy Policy, we will cover the “Serium Sector” video game software. We’ll refer to this as the “Service.”</p>
        <h2>3. What types of personal data do we collect?</h2>
        <p>Below you’ll find details about the types of personal data we collect from you and how we use it. We call this “processing” your data. </p>
        <p>We’ll also tell you the reason for processing that data, which is known as our “legal basis.”</p>
        <p>
            This is the personal data we collect from you:
        </p>
        <table>
            <tbody>
                <tr>
                    <th>Type of data</th>
                    <th>Examples of the data</th>
                    <th>How we use it</th>
                    <th>Our legal basis</th>
                </tr>
                <tr>
                    <td>Account Information</td>
                    <td>Your name, email address, and username</td>
                    <td>To create your Account on the Service, to contact you for technical and customer support purposes, and to send marketing and promotional emails concerning the Service</td>
                    <td>To fulfill a contract with you, under GDPR Art. 6 (1) (b). </td>
                </tr>
                <tr>
                    <td>Technical Information</td>
                    <td>Your IP Address</td>
                    <td>To operate the multiplayer aspects of the Service</td>
                    <td>To fulfill a contract with you, under GDPR Art. 6 (1) (b). </td>
                </tr>
                <tr>
                    <td>Posts</td>
                    <td>Your feedback about the Service and other communications with Far Planet Games</td>
                    <td>To improve the Service and for customer service and technical support purposes</td>
                    <td>To fulfill a contract with you, under GDPR Art. 6 (1) (b). </td>
                </tr>
            </tbody>
        </table>
		<p>
            We don’t currently share any personal data with any third parties, though we may need to share your personal data in a few situations:
            <ul>
                <li>To follow the law, a court order, or orders from government agencies</li>
                <li>To detect and combat fraud or security issues</li>
                <li>To protect the Service, our employees, and our business’s rights or safety</li>
            </ul>
        </p>
        <p>Other than that, we will not share your personal data with anyone else.</p>
        <h2>4. How long do we keep your personal data?</h2>
        <p>We only keep your personal data as long as it’s required to provide you with the Service. Sometimes a longer period might be required by law.</p>
        <p>After that, we will delete your personal data within a reasonable time.</p>
        <p>Please note that we may retain some data, if necessary to: 
            <ul>
                <li>resolve disputes, </li>
                <li>enforce our user agreements, </li>
                <li>follow any technical and legal requirements related to the Service.</li>
            </ul>
        </p>
        <h2>5. Children’s privacy rights</h2>
        <p>We don’t knowingly collect any personal data from children under the age of 13. We also don’t knowingly allow them to create accounts, sign up for newsletters, make purchases, or use the Service. </p>
        <p>We may also limit our personal data processing for EU users between 13 and 16.</p>
        <p>We take children’s privacy seriously and encourages parents to play an active role in their children’s online experience. If you have any concerns about your child’s personal data, please contact us at farplanetgames@gmail.com.</p>
        <h2>6. Transfers of your personal data</h2>
        <p>
            Far Planet Games is based in the United States.
        </p>
        <p>
            No matter where you live, by using the Service you consent to the processing and transfer of your personal data in and to the United States. This processing will be under the privacy policies of third parties that we share personal data with.
        </p>
        <p>
            The laws of these countries governing data collection and use may not be as comprehensive or protective as the laws of the country where you live.
        </p>
        <p>
            If you would like more information, please contact us (see “How to contact us” below).
        </p>
        <h2>6. EU residents’ rights</h2>
        <p>
            We are regulated under the General Data Protection Regulation (GDPR), which applies across the European Union (including in the United Kingdom). We are responsible as a controller of personal data for GDPR purposes.
        </p>
        <p>
            Under the GDPR, EU residents have several important rights: 
            <ul>
                <li>You can request a copy of your personal data</li>
                <li>You can ask us to correct that personal data, delete it, or request that we use it only for certain purposes.</li>
                <li>If you’ve consented to our processing, you can change your mind and ask us to stop using your personal data. For example, you can unsubscribe from our mailing list at any time – just click the link in each marketing email.</li>
                <li>In some circumstances, you can ask us not to use automated processing or profiling about you.</li>
            </ul>
        </p>
        <p>
            If you would like to exercise any of those rights, please email us at farplanetgames@gmail.com. We may ask for additional info to verify that you’re the owner of that data.
        </p>
        <p>
            Also, in some cases where the law requires it, we may not be able to help with the above requests.
        </p>
        <h2>8. Brazilian residents’ rights</h2>
        <p>We are regulated under the Lei Geral de Proteção de Dados (“LGPD”), which applies to Brazilian residents. </p>
        <p>
            Under the LGPD, Brazilian residents have several important rights: 
            <ul>
                <li>Know when we use your personal data</li>
                <li>Access your personal data, correct any errors, or delete your personal data</li>
                <li>Anonymize, block, or delete data that we don’t need or are not processing in compliance with the LGPD</li>
                <li>Request we transfer your data to another provider</li>
                <li>Be informed about who we share your data with</li>
                <li>Be informed about your ability to deny consent and any consequences </li>
                <li>To revoke your consent</li>
            </ul>
        </p>
        <p>
            These rights apply to any personal data collected or processed in Brazil, as well as any personal data processed for the purpose of providing goods or services in Brazil.
        </p>
        <p>
            If you would like to exercise any of those rights, please email us at farplanetgames@gmail.com. We may ask for additional info to verify that you’re the owner of that data.
        </p>
        <h2>9. How do we protect personal data?</h2>
        <p>We have taken steps and put security measures in place to prevent the accidental loss or misuse of personal data. </p>
        <p>For example, we limit access to those who have a genuine business need. Those processing your information will do so only in an authorized manner.</p>
        <p>We also have procedures in place to deal with any suspected data security breach. We’ll notify you and any applicable regulator of a suspected data security breach when legally required.</p>
        <h2>10. Resolving Disputes</h2>
        <p>
            We hope that we can resolve any questions or concerns you raise about our use of your personal data.
        </p>
        <p>
            Please contact us at farplanetgames@gmail.com to let us know if you have questions or concerns. We will do our best to resolve the issue.
        </p>
        <p>
            For EU residents, the GDPR also gives you right to lodge a complaint with a supervisory authority. You may do this in the EEA state where you live, work, or where any alleged infringement occurred.
        </p>
        <h2>11. How will we notify you of changes?</h2>
        <p>We last updated this Privacy Policy on 11/28/2020.</p>
        <p>We may make further updates from time to time. If we have your email address on file, we will inform you via email. Otherwise, we will post a message on the Service about the change.</p>
        <h2>12. How to contact us</h2>
        <p>Please contact us if you have any questions about this Privacy Policy or your personal data.</p>
        <p>You can do so using the following contact info:</p>
        <p>Email: farplanetgames@gmail.com</p>
    </div>
}

export default PrivacyPolicy;