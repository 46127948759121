import React, { useEffect } from 'react';

function PylotDL(props) {
    useEffect(() => {
        document.getElementById("music-dl").click();
    }, [])

    return <div style={{"height": "100vh"}}>
        <div style={{"transform": "translateY(-50%)", "position": "relative", "top": "40%"}}>
        <h1 style={{"fontSize": "4rem", "color": "white", "padding": "20px"}}>Your download should start shortly.</h1>
        <a href="https://www.dropbox.com/s/h0vibpmkwme3mxn/Serium%20Sector%20Trailer%20Music.wav?dl=1" id="music-dl" style={{"backgroundColor": "rgba(0, 0, 0, 0.75)", "padding": "10px"}} download>Click here if it does not automatically start.</a>;
        </div>        
    </div>
}

export default PylotDL;