import React, {useState} from 'react';
import bgVideo from '../media/bg-video-2.mp4';
import smolBgVideo from '../media/smol-bg-video.mp4';
import BodySection from './body-section';
import RoleCards from './role-cards';
import GameplayItems from './gameplay-items';
import DiscordIcon from '../media/discord-icon.svg';
import MailchimpForm from './mailchimp-form';
import MailModal from './mail-modal';
import seriumSector from '../media/serium-sector.png';
import seriumSectorMobile from '../media/serium-sector-mobile.png';
import heavyAR from  "../media/heavy-ar-v2.png";

function Home(props){
    const [showModal, setShowModal] = useState(false);

    return <React.Fragment>
        <div className="title-area">
          <video autoPlay muted loop src={""} className="d-block d-lg-none" style={{"backgroundImage": `url(${heavyAR}`, "backgroundSize": "cover", "minHeight": "600px", "backgroundPosition": "center", "filter":"blur(5px)"}}></video>
          <video autoPlay muted loop src={bgVideo} className="d-none d-lg-block" style={{minWidth: "100%", minHeight: "800px"}}></video>
          <section className="title-box">
            <div id="title-box-offset-container-div">
              {/* <h1 className="d-none d-lg-flex"><span className="d-none d-sm-inline">◠</span>Serium Sector<span className="d-none d-sm-inline">◠</span></h1> */}
              <h1 className="d-none d-lg-flex">
                  <img src={seriumSector} /> 
              </h1>
              {/* <h1 className="d-block d-lg-none mobile-header">Serium Sector</h1> */}
              <h1 className="d-block d-lg-none mobile-header"><img src={seriumSectorMobile} style={{maxWidth: "100vw"}} /></h1>
              <h2 style={{"fontFamily": "Electrolize", paddingTop: "20px"}}>RTS / FPS Hybrid</h2>
              <h2 style={{"fontFamily": "Electrolize", fontSize: "1.2rem"}}>Will you join the front lines? Or lead to victory from above?</h2>
              <h3>
                {/*<a href="https://discord.gg/gVpqW97">Discord <img src={DiscordIcon} style={{"width": "40px", "paddingLeft": "10px"}} alt="dc-icon" /></a><br />*/}
                <a className="signup-button-container d-inline-block" href="#" onClick={() => { setShowModal(true) }}>
                  <div href="#mc_embed_signup" className="email-link">
                    GET UPDATES  
                    <div className="link-square tl"> </div>
                    <div className="link-square br"> </div>
                  </div>
                </a>
              </h3>
              <div id="discord-link-container">
                <a href="https://discord.gg/gVpqW97" className="discord-link">Join our discord <img src={DiscordIcon} style={{"width": "40px", "paddingLeft": "10px"}} alt="dc-icon" /></a>
              </div>
            </div>
          </section>
        </div>
        <MailModal showModal={showModal} setShowModal={setShowModal}></MailModal>
        <BodySection>
          {/*<h2>Experience an immersive hybrid of Real Time Strategy and First Person Shooter.</h2>*/}
          <h3 className="feature-list d-none d-md-block">
            <ul>
              <li>Online Mulitiplayer</li>
              <li>- &nbsp;&nbsp;First Person Shooter</li>
              <li>- &nbsp;&nbsp;Real Time Strategy</li>
            </ul>
          </h3>
          {/* <img  className={"d-block d-lg-none "} src={killshot} style={{"padding": "20px", "maxWidth": "99%"}} />
          <img className={"d-none d-lg-inline"} src={heavyAR} style={{"padding": "20px", "maxWidth": "99%"}} />
          <p style={{"textDecoration":"none"}}><b>Serium Sector</b> is a <b>team-based multiplayer game.</b>  In each match, one player per team will assume the role of commander.</p>
          <p>The commander's job is to harvest resources, place production buildings, manage an economy, and build an army. Serium Sector is a fully-featured RTS.</p>
          <p>FPS players will fight alongside the RTS player's army.</p>
          <hr />
          <h2 className="section-header">Chose your role:</h2>
          <p>You decide how you want to play.  Two unique roles allow for endless gameplay.</p>
          <RoleCards />
          <MailchimpForm></MailchimpForm>
          <h2 className="section-header">Head to battle:</h2>
          <p>Face-off in highly competitive fast-paced online matches.</p>
          <GameplayItems /> */}
        </BodySection>
    </React.Fragment>
}

export default Home;